.page {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
}

.top-tags {
    bottom: auto;
    top: 35px;
}

.tags {
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore', cursive;
}

.bottom-tag-html {
    margin-left: -20px;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    -webkit-animation: fadeIn 1s forwards;
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
}

.container.contact-page,
.container.about-page,
.container.projects-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 40%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;
        margin-left: 20px;

        h1 {
            font-size: 80px;
            font-family: 'Coolvetica';
            color: #ffd700;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            left: -10px;
        }

        p {
            font-size: 18px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            max-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }
        }
    }

    .text-animate-hover {
        &:hover {
            color: #fff;
        }
    }
}

.container.projects-page {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .arrow {
        height: 50px;
        position: absolute;
        cursor: pointer;

        &.left {
            left: 90px;
            top: 50%;
        }

        &.right {
            right: 30px;
            top: 50%;
        }
    }

    h1 {
        font-size: 40px;
        font-family: 'Coolvetica';
        color: #ffd700;
        font-weight: 400;
        position: fixed;
        top: 20px;
        left: 150px;
    }
}

@media screen and (max-width: 1000px) {
    .container.projects-page {

        .arrow {
            &.left {
                left: 30px;
                top: 400px;
            }
            
            &.right {
                right: 30px;
                top: 400px;
            }
        }

        h1 {
            font-size: 40px;
            margin-top: 20px;
            margin-bottom: 0px;
            left: 100px;
        }
    }
}

@media screen and (max-width: 700px) {
    .container.projects-page {

        margin-top: 100px;

        .arrow {
            &.left {
                left: 10px;
                top: 250px;
            }

            &.right {
                right: 10px;
                top: 250px;
            }
        }

        h1 {
            font-size: 40px;
            margin-top: 20px;
            margin-bottom: 0px;
            left: 100px;
            display: none;
        }
    }
}

@media screen and (max-width: 700px) {
    .container {
        min-height: 0;
        height: 0;
        justify-content: center;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
}

@media screen and (max-width: 700px) {
    .container.about-page {
        display: flex;
        flex-direction: column;
    
        .text-zone {
            position: relative;
            transform: translateY(0);
            display: flex;
            flex-direction: column;
            width: 70%;
    
            h1 {
                font-size: 28px;
                font-family: 'Coolvetica';
                color: #ffd700;
                font-weight: 700;
                margin-top: 0;
                position: relative;
                margin-bottom: 0px;
                left: -10px;
                margin-top: 20px;
    
                &:before {
                    display: none;
                }
    
                &:after {
                    display: none;
                }
            }
    
            p {
                max-width: 100% !important;
                min-width: 100%;
                width: 100%;
                font-size: 17px;
                margin-bottom: 8px;
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .container.about-page {
        .text-zone {
            h1 {
                font-size: 50px;
            }
        }
    }
}

@media screen and (max-height: 700px) {
    .container.about-page {
        .text-zone {
            h1 {
                font-size: 22px;
            }

            p {
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-height: 700px) {
    .container.projects-page {
    
        .arrow {
            &.left {
                left: 10px;
                top: 80%;
            }  
            &.right {
                right: 14px;
                top: 80%;
            }
        }
    
        h1 {
            font-size: 40px;
            margin-top: 20px;
            margin-bottom: 0px;
            display: none;
    
            &:before {
                display: none;
            }
    
            &:after {
                display: none;
            }
        }
    }
}