.home-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 80%;
        max-height: 90%;
        margin-left: 20px;
    }

    h1 {
        color: #fff;
        font-size: 56px;
        line-height: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
        animation: fadeIn 0s 0s backwards;
    }

    h2 {
        color: #ffd700;
        margin-top: 20px;
        font-weight: 400;
        font-size: 13px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #ffd700;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 3px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        border-radius: 10px;
        margin-right: 20px;
        text-align: center;

        &:hover {
            background: #ffd700;
            color: #333;
        }
    }
}

@media screen and (max-width: 700px) {
    .home-page {
        .text-zone {
            display: flex;
            flex-direction: column;
            transform: translateY(0);
            width: 70%;
            max-height: 90%;
            margin-top: 200px;
        }
    
        h1 {
            color: #fff;
            font-size: 200%;
            line-height: 30px;
            margin: 0;
            font-family: 'Coolvetica';
            font-weight: 700;
            letter-spacing: 5px;
            animation: fadeIn 1s .8s backwards;
    
            &:before {
                display: none;
            }
    
            &:after {
                display: none;
            }
        }
    
        h2 {
            color: #ffd700;
            margin-top: 20px;
            font-weight: 400;
            font-size: 12px;
            font-family: sans-serif;
            letter-spacing: 3px;
            animation: fadeIn 1s .8s backwards;
        }
    
        .flat-button {
            color: #ffd700;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 2px;
            font-family: sans-serif;
            text-decoration: none;
            padding: 10px 10px;
            border: 1px solid #ffd700;
            margin-top: 25px;
            float: left;
            animation: fadeIn 1s 0.8s backwards;
            white-space: nowrap;
            border-radius: 10px;
            max-width: 30%;
            width: 180px;
            margin-right: 10px;
            text-align: center;
    
            &:hover {
                background: #ffd700;
                color: #333;
            }
        }
    }
}

.home-links {
    padding-left: 0px;
    display: flex;
    justify-content: flex-start;
}

@media screen and (max-width: 300px) {
    .home-links {
        flex-wrap: wrap;
    }
}